import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const baseCss = css`
  padding-left: 2em;
  padding-right: 2em;
  height: 42px;
  line-height: 42px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  padding-left: 2em;
  padding-right: 2em;
  display: inline-block;
  text-align: center;
  border: 0;
  outline: 0;
  cursor: pointer;
  background-color: #d14242;
  transition: all 0.3s;

  svg {
    display: inline-block;
    vertical-align: text-bottom;
  }

  &:hover {
    background-color: #de4c47;
  }
  &:focus {
    background-color: #ab2e32;
  }
  &:disabled {
    background-color: #b3bac5;
  }
`;

const InternalLink = styled(Link)`
  ${baseCss}
  line-height: 42px;
`;

const ExternalLink = styled.a`
  ${baseCss}
`;

const PureButton = styled.button`
  ${baseCss}
`;

const MyButton = ({ children, href, to, ...props }) => {
  if (href) {
    return (
      <ExternalLink href={href} {...props}>
        {children}
      </ExternalLink>
    );
  }

  if (to) {
    return (
      <InternalLink to={to} {...props}>
        {children}
      </InternalLink>
    );
  }

  return <PureButton {...props}>{children}</PureButton>;
};

MyButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string
};

export default MyButton;
