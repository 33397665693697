import OutlineArrowChevronRight from '@dolstaff/shared/es/icons/OutlineArrowChevronRight';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../style/colors-v2';
import { checkIfLinkOfCurrentPageIsActiveByPath } from '../utils/checkIfLinkOfCurrentPageIsActive';
import { fromScreen } from '../utils/media-query/responsive.util';
import { NavMenuItem } from './NavMenuItem';

const Main = styled.div`
  display: none;
  ${fromScreen(1260)} {
    display: grid;
  }
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 8px;
  height: 100%;
  align-items: center;
  justify-content: flex-end;

  .nav-list {
    border-bottom: 2px solid transparent;
    height: 100%;
    display: grid;
    place-items: center;
    cursor: pointer;
    a {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;
      color: ${colorsV2.black100};
    }
    &:hover,
    &.active {
      border-color: ${colorsV2.primary100};
      a {
        color: ${colorsV2.primary100};
      }
    }
  }

  .simple-groups-wrapper {
    display: none;
    position: fixed;
    top: 64px;
    padding-top: 8px;
    background-color: transparent;
    z-index: 60;
  }

  .sub-menu-groups-wrapper {
    display: none;
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    box-shadow: rgb(0 0 0 / 4%) 0px 10px 20px, rgb(0 0 0 / 4%) 0px 2px 6px,
      rgb(0 0 0 / 4%) 0px 0px 1px;
    z-index: 60;
  }

  .sub-menu-group-items {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    max-width: 1366px;
    margin: 0 auto;
    gap: 24px;
    &.column-4-1 {
      grid-template-columns: 4fr 1fr;
    }
    &.column-3-2 {
      grid-template-columns: 3fr 2fr;
    }
  }

  .simple-group-items {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 16px;
    padding: 32px;

    background-color: white;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    .sub-menu-group {
      display: grid;
      grid-auto-rows: max-content;
      gap: 16px;
      > .sub-menu {
        a {
          font-weight: 700;
          text-transform: uppercase;
          font-size: 16px;
          line-height: 24px;
          &:hover {
            color: ${colorsV2.black100};
            cursor: auto;
          }
        }
      }
    }
  }

  .sub-menu-groups-wrapper {
    .sub-menu-group {
      padding: 40px 20px 40px 32px;
      display: grid;
      grid-auto-rows: max-content;
      gap: 24px 0;
      position: relative;
      &:last-child {
        padding: 40px 100px 40px 60px;
        &:before {
          z-index: -1;
          content: ' ';
          background: ${colorsV2.gray5};
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          bottom: 0;
          width: 100vw;
        }
      }
    }
    .sub-menu {
      .menu-item {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-transform: uppercase;
      }
    }
  }

  .sub-menu-list-items {
    display: none;
    position: absolute;
    top: 64px;
    left: 0;

    z-index: 200;
    box-shadow: rgb(0 0 0 / 4%) 0px 10px 20px, rgb(0 0 0 / 4%) 0px 2px 6px,
      rgb(0 0 0 / 4%) 0px 0px 1px;
  }

  .sub-menu-groups {
    height: 100%;
    display: grid;
    place-items: center;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    a {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;
      color: ${colorsV2.black100};
    }
    &:hover {
      border-color: ${colorsV2.primary100};
      a {
        color: ${colorsV2.black100};
      }
    }

    .group-wrapper {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      margin: 0 auto;
      gap: 24px;
    }

    .group-items {
      display: grid;
      gap: 16px;

      padding-right: 24px;
      grid-auto-rows: max-content;
      justify-content: flex-start;
      &:last-child {
        padding-right: 0;
      }
    }

    .group-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .group-list {
      display: grid;
      grid-auto-rows: max-content;
      gap: 12px;
      .menu-item {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }
      a {
        color: ${colorsV2.black100};
        &:hover {
          color: ${colorsV2.primary100};
        }
      }
      .nav-menu-item.empty-link {
        a {
          color: ${colorsV2.black60};
          &:hover {
            color: ${colorsV2.black60};
          }
        }
      }
    }

    &.active {
      border-color: ${colorsV2.primary100};
      > .sub-menu {
        color: ${colorsV2.primary100};
        a {
          color: ${colorsV2.primary100};
        }
      }
    }
    &:hover {
      > .sub-menu {
        color: ${colorsV2.primary100};
        a {
          color: ${colorsV2.primary100};
        }
      }
      .simple-groups-wrapper {
        display: grid;
      }
      .sub-menu-groups-wrapper {
        display: grid;
        background-color: ${colorsV2.white100};
      }
    }
  }

  .group-item.active {
    .nav-menu-item a {
      color: ${colorsV2.primary100};
    }
  }

  .sub-menu-list {
    height: 100%;
    display: grid;
    place-items: center;
    border-bottom: 2px solid transparent;
    position: relative;
    cursor: pointer;

    a {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;
      color: ${colorsV2.black100};
    }

    .group-wrapper {
      display: grid;
      width: 200px;
      grid-auto-flow: row;
      border-radius: 8px;
      padding: 8px 0px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 0 3px rgb(0 0 0 / 16%);
      border-radius: 5px;
    }

    .group-item {
      cursor: pointer;
      width: 100%;

      &:hover {
        background-color: rgb(244, 244, 246);
        box-shadow: none;
        border-bottom: none;
      }
      .nav-menu-item {
        a {
          padding: 12px 16px;
          width: 100%;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    &:hover,
    &.active {
      .sub-menu {
        div {
          color: ${colorsV2.primary100};
        }
      }
      border-color: ${colorsV2.primary100};
    }
    &:hover {
      > .sub-menu {
        color: ${colorsV2.primary100};
        a {
          color: ${colorsV2.primary100};
        }
      }
      .sub-menu-list-items {
        display: block;
      }
    }
  }

  .sub-menu {
    height: 100%;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 4px;
    cursor: pointer;
    .icon-arrow {
      transform: rotate(90deg);
    }
  }

  ${fromScreen(1144)} {
    gap: 24px;
  }
`;

const normalizeListItem = (item, newList = []) => {
  if (item) {
    if (item.link) {
      newList.push(item.link);
    }
    if (item.subMenu && item.subMenu.length > 0) {
      item.subMenu.forEach(sMenu => normalizeListItem(sMenu, newList));
    }
  }

  return newList;
};

const calculateSimpleGroupWrapper = ({ elementRef }) => {
  const el = elementRef.current;
  if (el) {
    const position = el.getBoundingClientRect();
    if (position.width + position.x > window.innerWidth) {
      el.style.right = 0;
    }
  }
};

const SimpleGroupComponent = ({
  isActive,
  index,
  item,
  subMenu,
  renderMenuItem,
  renderItem
}) => {
  const ref = React.useRef();

  return (
    <div
      key={index + item?.text}
      onMouseEnter={() => {
        calculateSimpleGroupWrapper({ elementRef: ref });
      }}
      className={cl('sub-menu-groups', {
        active: isActive
      })}
    >
      <div className="sub-menu">
        {renderItem(item)}

        <div className="icon-arrow">
          <OutlineArrowChevronRight height={12} width={12} />
        </div>
      </div>
      <div
        ref={ref}
        style={{ width: item.width || 'max-content' }}
        className="simple-groups-wrapper"
      >
        <div className={cl('simple-group-items')}>
          {subMenu.map((sMenu, sMenuIndex) => (
            <React.Fragment key={sMenuIndex}>
              {renderMenuItem(sMenu, sMenuIndex)}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export const DesktopMenuList = ({ data = [] }) => {
  const [currentPath, setCurrentPath] = React.useState('');

  React.useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  const renderItem = React.useCallback(item => {
    return (
      <NavMenuItem
        key={item.text + item.link}
        text={item.text}
        link={item.link}
        external={item.external}
      />
    );
  }, []);

  const renderMenuItem = React.useCallback(
    (item, index) => {
      const { subMenu, type } = item;
      const isLinkActive = checkIfLinkOfCurrentPageIsActiveByPath(
        currentPath,
        item.link
      );
      if (subMenu && subMenu.length > 0) {
        switch (type) {
          case 'simple-groups': {
            const isActive = subMenu.some(s => {
              if (s?.subMenu?.length > 0) {
                return s.subMenu.some(g =>
                  checkIfLinkOfCurrentPageIsActiveByPath(currentPath, g.link)
                );
              }
              return false;
            });
            return (
              <SimpleGroupComponent
                key={index + type}
                isActive={isActive}
                index={index}
                item={item}
                subMenu={subMenu}
                renderMenuItem={renderMenuItem}
                renderItem={renderItem}
              />
            );
          }
          case 'simple-group': {
            return (
              <div key={index + type} className="sub-menu-group">
                <div className="sub-menu">{renderItem(item)}</div>
                <div className="group-list">
                  {subMenu.map((group, gIndex) => {
                    return (
                      <div
                        key={gIndex}
                        className={cl('group-item', {
                          active: checkIfLinkOfCurrentPageIsActiveByPath(
                            currentPath,
                            group.link
                          )
                        })}
                      >
                        {renderItem(group)}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }
          case 'groups': {
            const columnNames = subMenu
              .map(n => n.subMenu.filter(elm => !elm.mobileOnly)?.length)
              .join('-');
            const flattenList = normalizeListItem(item);
            const isActive = flattenList.some(itemLink => {
              return checkIfLinkOfCurrentPageIsActiveByPath(
                currentPath,
                itemLink
              );
            });
            return (
              <div
                key={index + item?.text}
                className={cl('sub-menu-groups', {
                  active: isActive
                })}
              >
                <div className="sub-menu">
                  {renderItem(item)}

                  <div className="icon-arrow">
                    <OutlineArrowChevronRight width={12} />
                  </div>
                </div>
                <div className="sub-menu-groups-wrapper">
                  <div
                    className={cl(
                      'sub-menu-group-items',
                      `column-${columnNames}`
                    )}
                  >
                    {subMenu.map((sMenu, sMenuIndex) => (
                      <React.Fragment key={sMenuIndex}>
                        {renderMenuItem(sMenu, sMenuIndex)}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            );
          }
          case 'group': {
            return (
              <div className="sub-menu-group" key={index + item?.text}>
                <div className="sub-menu">{renderItem(item)}</div>
                <div className="group-items">
                  <div className="group-wrapper">
                    {subMenu.map((group, gIndex) => {
                      if (group.mobileOnly) return;
                      return (
                        <div key={gIndex} className={'group-items'}>
                          {group.text && (
                            <a href={group.link}>
                              {<div className="group-title">{group.text}</div>}
                            </a>
                          )}
                          <div className="group-list">
                            {group.subMenu.map((subItem, subItemIndex) => {
                              return (
                                <div
                                  key={subItemIndex}
                                  className={cl('group-item', {
                                    active:
                                      checkIfLinkOfCurrentPageIsActiveByPath(
                                        currentPath,
                                        subItem.link,
                                        true
                                      )
                                  })}
                                >
                                  {renderItem(subItem)}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          }
          default:
        }
        return (
          <div
            key={index + item?.text}
            className={cl('sub-menu-list', {
              active: subMenu.some(g =>
                checkIfLinkOfCurrentPageIsActiveByPath(currentPath, g.link)
              )
            })}
          >
            <div className="sub-menu">
              {renderItem(item)}

              <div className="icon-arrow">
                <OutlineArrowChevronRight width={12} />
              </div>
            </div>
            <div className="sub-menu-list-items">
              <div className="group-wrapper">
                {subMenu.map((group, gIndex) => {
                  return (
                    <div
                      key={gIndex}
                      className={cl('group-item', {
                        active: checkIfLinkOfCurrentPageIsActiveByPath(
                          currentPath,
                          group.link
                        )
                      })}
                    >
                      {renderItem(group)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          key={index}
          className={cl('nav-list', {
            active: isLinkActive
          })}
        >
          {renderItem(item)}
        </div>
      );
    },
    [currentPath, renderItem]
  );

  return (
    <Main className="desktop-menu-list">
      {data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {renderMenuItem(item, index)}
          </React.Fragment>
        );
      })}
    </Main>
  );
};
