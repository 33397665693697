import { betweenWidth, fromWidth, onlyWidth, toWidth } from './media-query';
import * as React from 'react';

export const responsive = props => props.responsive;

export const fromScreen = minWidth =>
  `@media screen and ${fromWidth(minWidth)}`;

export const toScreen = maxWidth => `@media screen and ${toWidth(maxWidth)}`;

export const betweenScreen = (minWidth, maxWidth) =>
  `@media screen and ${betweenWidth(minWidth, maxWidth)}`;

export const onlyScreen = (width, nextBreakPoint) =>
  `@media screen and ${onlyWidth(width, nextBreakPoint)}`;

export const isFirst = index => index === 0;
export const isLast = (index, arr) => index === arr.length - 1;

export const toMediaQuery = data => {
  let lastBreakpoint;
  return data.map(({ breakpoint, ...rest }, index, arr) => {
    let query;
    if (isFirst(index)) {
      query = toScreen(breakpoint);
    } else if (isLast(index, arr)) {
      query = fromScreen(breakpoint);
    } else {
      query = betweenScreen(lastBreakpoint, breakpoint);
    }
    lastBreakpoint = breakpoint;
    return { ...rest, breakpoint, query };
  });
};

export const responsiveOnBreakpoints = (p, cssFn) => {
  return toMediaQuery(p).reduce(
    (currentValue, item) => `${currentValue}
    ${item.query} {
      ${cssFn(item)}
    }`,
    ''
  );
};

const matchAllMediasByMinWidths = minWidths => {
  return minWidths.map(query => window.matchMedia(fromWidth(query)));
};

export const useFromScreens = fromScreens => {
  const [state, setState] = React.useState(
    fromScreens.map((_, index) => {
      return index !== 0;
    })
  );

  React.useEffect(() => {
    setState(
      matchAllMediasByMinWidths(fromScreens).map(query => query.matches)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let mounted = true;
    const mediaQueries = matchAllMediasByMinWidths(fromScreens);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(mediaQueries.map(query => !!query.matches));
    };

    document.addEventListener('resize', onChange, true);

    return () => {
      mounted = false;
      document.removeEventListener('resize', onChange, true);
    };
  }, [fromScreens]);

  return state;
};

// export const fromWidth = (minWidth: number) => `@media (min-width: ${minWidth}px)`;
//
// export const toWidth = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;
//
// export const betweenWidth = (minWidth: number, maxWidth: number) =>
//   `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
//
// export const onlyWidth = (width: number, nextBreakPoint: number) =>
//   `@media (min-width: ${width}px) and (max-width: ${nextBreakPoint - 0.02}px)`;
