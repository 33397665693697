import axios from 'axios';

export const getUserIP = () => {
  return axios
    .get('https://api.ipify.org/?format=json')
    .then(({ data }) => data.ip);
};

export const getUserIPV6 = () => {
  return axios
    .get('https://api64.ipify.org/?format=json')
    .then(({ data }) => data.ip);
};
