import { isNil } from 'lodash';
import { fromScreen } from '../../utils/media-query/responsive.util';

import { colorsV2 } from '../../style/colors-v2';

const validBreakPointProps = [
  'color',
  'm',
  'mt',
  'marginTop',
  'mr',
  'marginRight',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mx',
  'marginX',
  'my',
  'marginY',
  'p',
  'pt',
  'paddingTop',
  'pr',
  'paddingRight',
  'pb',
  'paddingBottom',
  'pl',
  'paddingLeft',
  'px',
  'paddingX',
  'py',
  'paddingY'
];

const resolveSystemColor = colorName => {
  return colorsV2[colorName] || colorName;
};

const resolveNumberOrPx = value => {
  if (typeof value === 'string') {
    return value;
  }
  return `${value}px`;
};

const resolveAttr = (attrName, attrValue) => {
  switch (attrName) {
    case '$color': {
      return `color: ${resolveSystemColor(attrValue)};`;
    }
    case '$m':
      return `margin: ${resolveNumberOrPx(attrValue)};`;

    case '$mt':
    case '$marginTop':
      return `margin-top: ${resolveNumberOrPx(attrValue)};`;

    case '$mr':
    case '$marginRight':
      return `margin-right: ${resolveNumberOrPx(attrValue)};`;

    case '$mb':
    case '$marginBottom':
      return `margin-bottom: ${resolveNumberOrPx(attrValue)};`;

    case '$ml':
    case '$marginLeft':
      return `margin-left: ${resolveNumberOrPx(attrValue)};`;

    case '$mx':
    case '$marginX':
      return `margin-left: ${resolveNumberOrPx(attrValue)};
      margin-right: ${resolveNumberOrPx(attrValue)};`;

    case '$my':
    case '$marginY':
      return `margin-top: ${resolveNumberOrPx(attrValue)};
      margin-bottom: ${resolveNumberOrPx(attrValue)};`;

    case '$p':
      return `padding: ${resolveNumberOrPx(attrValue)};`;

    case '$pt':
    case '$paddingTop':
      return `padding-top: ${resolveNumberOrPx(attrValue)};`;

    case '$pr':
    case '$paddingRight':
      return `padding-right: ${resolveNumberOrPx(attrValue)};`;

    case '$pb':
    case '$paddingBottom':
      return `padding-bottom: ${resolveNumberOrPx(attrValue)};`;

    case '$pl':
    case '$paddingLeft':
      return `padding-left: ${resolveNumberOrPx(attrValue)};`;

    case '$px':
    case '$paddingX':
      return `padding-left: ${resolveNumberOrPx(attrValue)};
      padding-right: ${resolveNumberOrPx(attrValue)};`;

    case '$py':
    case '$paddingY':
      return `padding-top: ${resolveNumberOrPx(attrValue)};
      padding-bottom: ${resolveNumberOrPx(attrValue)};`;
    default:
  }
  return `${attrName}: ${resolveNumberOrPx(attrValue)};`;
};

export const toTransientProps = (propName, extraBreakPointProps = []) =>
  [...validBreakPointProps, ...extraBreakPointProps].includes(propName)
    ? `$${propName}`
    : propName;

export const responsiveBreakPointPropsToTrasientProps = (
  props,
  extraBreakPointProps = []
) => {
  const result = {};
  Object.keys(props).forEach(propName => {
    result[toTransientProps(propName, extraBreakPointProps)] = props[propName];
  });
  return result;
};

const memGeneratedStyled = {};

const genStyledCode = ({
  breakpoints,
  customResolveAttrs,
  p,
  filteredProps
}) => {
  if (breakpoints) {
    // to merge all mixins into breakpoints
    const breakPointsHashMap = {};
    breakpoints.forEach((breakPoint, breakPointIndex) => {
      const styleCodeArr = filteredProps.map(attrName => {
        let attrValue = p[attrName];
        if (p[attrName] instanceof Array) {
          attrValue = isNil(p[attrName][breakPointIndex])
            ? p[attrName][0]
            : p[attrName][breakPointIndex];
        }
        if (
          validBreakPointProps
            .map(propName => toTransientProps(propName))
            .includes(attrName)
        ) {
          return resolveAttr(attrName, attrValue);
        }
        return customResolveAttrs(attrName, attrValue);
      });
      breakPointsHashMap[breakPoint] = styleCodeArr.join(' ');
    });
    const keys = Object.keys(breakPointsHashMap);
    if (
      breakPointsHashMap[keys[0]] === breakPointsHashMap[keys[1]] &&
      breakPointsHashMap[keys[1]] === breakPointsHashMap[keys[2]]
    ) {
      return `${breakPointsHashMap[keys[0]]}`;
    }
    if (breakPointsHashMap[keys[1]] === breakPointsHashMap[keys[2]]) {
      return `
      ${breakPointsHashMap[keys[0]]}
      ${fromScreen(Number(keys[1]))} {
        ${breakPointsHashMap[keys[1]]}
      }`;
    }
    return Object.keys(breakPointsHashMap)
      .map(fromScreenWidth => {
        if (Number(fromScreenWidth) === 0) {
          return `${breakPointsHashMap[fromScreenWidth]}`;
        }
        return `${fromScreen(Number(fromScreenWidth))} {
          ${breakPointsHashMap[fromScreenWidth]}
        }`;
      })
      .join(' ');
  }
  return filteredProps
    .map(attrName => {
      if (p[attrName] instanceof Array) {
        return resolveAttr(attrName, p[attrName]);
      }
      return resolveAttr(attrName, p[attrName]);
    })
    .join(' ');
};

export const composeBreakPointStyle = (
  p,
  blackListProps = [],
  customResolveAttrs = resolveAttr
) => {
  const breakpoints = p.breakpoints || [0, 768, 1280];
  const filteredProps = Object.keys(p).filter(
    attrName =>
      attrName &&
      ![
        'breakpoints',
        'theme',
        'children',
        'className',
        'data-testid',
        'style',
        ...blackListProps
      ].includes(attrName) &&
      attrName.startsWith('$')
  );
  const hash = `${filteredProps
    .map(item => `${item}_${p[item]}`)
    .join('-')}||${breakpoints.join('_')}`;
  if (memGeneratedStyled[hash]) {
    return memGeneratedStyled[hash];
  }
  const genCode = genStyledCode({
    breakpoints,
    customResolveAttrs,
    p,
    filteredProps
  });
  memGeneratedStyled[hash] = genCode;
  return genCode;
};

export const numberToPxCss = input => {
  const whitelist = ['inherit', 'auto', 'initial'];
  if (whitelist.includes(input)) {
    return input;
  }
  if (!input.includes('px') && !input.includes('em')) {
    return `${input}px`.trim();
  }
  return `${input}`.trim();
};
