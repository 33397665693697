/* eslint-disable react/prop-types */
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import React from 'react';
import { fromScreen } from 'utils/media-query/responsive.util';
import { getAssetLinkByHandle } from '../utils/getAssetLink';
import { BranchExpandCollapse } from './BranchExpandCollapse';
import { ColumnContact } from './ColumnContact';
import { ColumnInfo } from './ColumnInfo';
import { DmcaFooter } from './DmcaFooter';
import { FollowDol } from './FollowDol';

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 40px;
`;

const Main = styled.footer`
  padding: 32px 16px;
  background-color: ${colorsV2.black100};
  ${fromScreen(458)} {
    padding: 32px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 32px;
`;

const CenterList = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 36px;
`;

const CourseLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: start;
  gap: 32px;

  .right-area {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    gap: 24px;
  }

  ${fromScreen(776)} {
    gap: 60px;
  }
`;

const Layout = styled.div`
  display: grid;
  gap: 24px;

  ${fromScreen(776)} {
    gap: 60px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const CommonFooter = ({ data = {}, shouldRenderDMCA = false }) => {
  return (
    <Main className="common-footer" id="footer">
      <Container>
        <Content>
          <img
            src={getAssetLinkByHandle({ handle: 'sFYcPsHEQbKIZMTiAGtL' })}
            alt="Logo DOL English"
          />
          <Layout>
            <CenterList>
              <BranchExpandCollapse
                title={`CƠ SỞ TẠI TPHCM (${data.centerHcm?.length})`}
                list={data.centerHcm}
              />
              <BranchExpandCollapse
                title={`CƠ SỞ TẠI HÀ NỘI (${data.centerHN?.length})`}
                list={data.centerHN}
              />
              <BranchExpandCollapse
                title={`CƠ SỞ TẠI ĐÀ NẴNG (${data.centerDN?.length})`}
                list={data.centerDN}
              />
              <ColumnContact title="THÔNG TIN LIÊN HỆ" list={data.contact} />
              <FollowDol list={data.socials} />
            </CenterList>
            <CourseLayout>
              <ColumnInfo title="KHÓA HỌC IELTS" list={data.courses} />
              <div className="right-area">
                <ColumnInfo title="KHÓA HỌC KHÁC" list={data.anotherCourses} />
                <ColumnInfo title="VỀ DOL" list={data.aboutDol} />
              </div>
            </CourseLayout>
          </Layout>
        </Content>
        <DmcaFooter list={data.dmca} shouldRenderDMCA={shouldRenderDMCA} />
      </Container>
    </Main>
  );
};
