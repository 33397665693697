/* eslint-disable react/prop-types */
import OutlineArrowChevronDown from '@dolstaff/shared/es/icons/OutlineArrowChevronDown';
import cl from 'classnames';
import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';
import { DividerLineDivider } from 'components/Divider/LineDivider/DividerLineDivider';
import * as React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';

const Main = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 12px;

  a {
    color: inherit;
    &:hover {
      color: ${colorsV2.primary100};
    }
  }
`;

const TextLinkWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &.expanded {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const TextLink = ({ onClick, expanded }) => {
  return (
    <TextLinkWrapper
      className={cl('text-link', { expanded })}
      onClick={onClick}
    >
      <ResponsiveTypography.Paragraph variant="semi-bold/14-20" color="gray120">
        {expanded ? 'Thu gọn' : 'Xem thêm'}
      </ResponsiveTypography.Paragraph>
      <OutlineArrowChevronDown color={colorsV2.gray120} />
    </TextLinkWrapper>
  );
};

export const BranchExpandCollapse = ({ title, list }) => {
  const [expanded, setExpanded] = React.useState(false);

  const data = React.useMemo(() => {
    if (!expanded) {
      return list?.length > 3 ? list.slice(0, 3) : list;
    }
    return list;
  }, [expanded, list]);

  return (
    <Main>
      <ResponsiveTypography.Paragraph variant="bold/14-20" color="gray100">
        {title}
      </ResponsiveTypography.Paragraph>
      <DividerLineDivider
        direction="horizontal"
        size={1}
        color={colorsV2.black80}
      />
      {(data || []).map((item, idx) => (
        <ResponsiveTypography.Paragraph
          variant="regular/14-20"
          color="white100"
          key={idx}
        >
          <b>Cơ sở {item?.centerNumber}: </b>
          <a href={item.mapLink} target="_blank" key={idx} rel="noreferrer">
            {item.address}
          </a>
        </ResponsiveTypography.Paragraph>
      ))}

      {list?.length > 3 && (
        <TextLink onClick={() => setExpanded(o => !o)} expanded={expanded} />
      )}
    </Main>
  );
};
