import styled from 'styled-components';

const Title = styled.h1`
  font-size: 24px;
  margin: 0;
  margin-bottom: 0.5em;
  line-height: 1.2;

  .em {
    &.em-title {
      margin-right: 5px;
      margin-left: 0;
    }
    margin: 0 5px;
  }
  em {
    font-style: normal;
  }

  .em,
  strong,
  em,
  b {
    color: #d14242;
  }

  &.modern {
    border-bottom: 2px solid #2d3742;
    text-transform: unset;
    padding-bottom: 0.5em;
    font-size: 14px;
    font-weight: normal;
  }

  &.small {
    font-weight: normal;
  }
  &.big {
    font-size: 28px;
  }

  @media screen and (min-width: 768px) {
    font-size: 28px;

    &.small {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 1024px) {
    font-size: 32px;

    .em.block {
      display: block;
    }

    &.small {
      font-size: 24px;
    }

    &.big {
      font-size: 40px;
    }
  }
`;

export const H2 = styled(Title).attrs({ as: 'h2' })``;
export const H3 = styled(Title).attrs({ as: 'h2' })``;

export default Title;
