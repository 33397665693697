/* eslint-disable react/prop-types */
import styled from 'styled-components';
import * as React from 'react';

import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';
import { getAssetLinkByHandle } from '../utils/getAssetLink';

const SocialIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const Main = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 8px;

  .list {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 16px;
  }
`;

const Social = ({ link, icon, alt }) => (
  <a
    aria-label={alt || icon || 'social'}
    href={link}
    rel="nofollow noreferrer"
    target="_blank"
  >
    <SocialIcon
      src={getAssetLinkByHandle({ handle: icon })}
      alt={alt || icon}
    />
  </a>
);

export const FollowDol = ({ list }) => {
  return (
    <Main>
      <ResponsiveTypography.Paragraph variant="bold/14-20" color="white100">
        Theo dõi DOL tại
      </ResponsiveTypography.Paragraph>
      <div className="list">
        {(list || []).map((item, itemIndex) => {
          return <Social key={itemIndex} {...item} />;
        })}
      </div>
    </Main>
  );
};
