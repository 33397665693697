import axios from 'axios';

import * as basedURL from './baseURL';

const header = {
  'Content-Type': 'application/json'
};

export const trackingUserPageViewEvent = payload => {
  return axios.post(
    `${basedURL.GO_USER_SERVICE}/api/page-view`,
    payload,
    header
  );
};

export const trackingUserClickEvent = payload => {
  return axios.post(`${basedURL.GO_USER_SERVICE}/api/click`, payload, header);
};
