import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { Typography } from '../../components/DesignSystemV2/Typography';
import { shaking } from './animations/shaking';
import { Link } from 'gatsby';
import { fromScreen } from '../../utils/media-query/responsive.util';
import { LazyImage } from 'components/LazyImage';

const DEFAULT_TOP = 150;
const SPACING_BETWEEN_CTA = 136;
const CTACardLayout = styled.div`
  display: none;
  ${fromScreen(1144)} {
    border: 1px solid ${colorsV2.gray40};
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px 0px 0px 8px;
    padding: 12px;
    display: grid;
    grid-row-gap: 8px;
    width: 95px;
    position: fixed;
    top: ${props => props.top || DEFAULT_TOP}px;
    right: 0;
    z-index: 101;
    background-color: ${colorsV2.white100};
    justify-items: center;
    text-align: center;

    transition: all 300ms;
    ${shaking}
  }
`;
const IconWrapper = styled.div`
  background-color: ${props => props.backgroundColor || colorsV2.primary100};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    color: ${colorsV2.white100};
  }
`;
const Title = props => {
  return <Typography variant="semi-bold/14-20" {...props} />;
};
export const CTAForScheduleAndRegister = () => {
  return (
    <>
      <Link to="/hall-of-fame">
        <CTACardLayout>
          <IconWrapper className="shaking">
            <LazyImage handle="u5mY69cEQiWeqdkJL576" alt="điểm và review" />
          </IconWrapper>
          <Title>Điểm & Review</Title>
        </CTACardLayout>
      </Link>

      <a
        href="https://superlms.dolenglish.vn"
        rel="noopener noreferrer"
        target="_blank"
      >
        <CTACardLayout top={DEFAULT_TOP + SPACING_BETWEEN_CTA}>
          <IconWrapper className="shaking" backgroundColor={colorsV2.green100}>
            <LazyImage handle="CoyjS6ZbRaorJNNNUJJQ" alt="đội ngũ giáo viên" />
          </IconWrapper>
          <Title>DOL SuperLMS</Title>
        </CTACardLayout>
      </a>

      <Link to="/gioi-thieu-linearthinking">
        <CTACardLayout
          top={DEFAULT_TOP + SPACING_BETWEEN_CTA + SPACING_BETWEEN_CTA}
        >
          <IconWrapper className="shaking" backgroundColor={colorsV2.yellow100}>
            <LazyImage handle="G1h9G0A1TXqXQhkBr0Jd" alt="linearthinking" />
          </IconWrapper>
          <Title>Linear-thinking</Title>
        </CTACardLayout>
      </Link>
    </>
  );
};
