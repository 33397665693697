import * as React from 'react';

const ContactIcon = props => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#contact-icon)">
      <path
        d="M20 5.08v7.464a.533.533 0 0 1-.533.533H5.073a.533.533 0 0 1-.533-.533V1.88c0-.294.239-.533.533-.533h14.394c.295 0 .533.239.533.533V5.08Z"
        fill="#FF5364"
      />
      <path
        d="M19.867 1.532 12.784 7.58a.797.797 0 0 1-1.029 0L4.673 1.532a.528.528 0 0 1 .4-.184h14.394c.154 0 .3.067.4.184Z"
        fill="#FB7B76"
      />
      <path
        d="M19.81 12.948a.526.526 0 0 1-.345.128H5.073a.527.527 0 0 1-.345-.128l5.905-6.325 1.122.957c.297.25.732.25 1.029 0l1.12-.957 5.906 6.325Z"
        fill="#DF4D60"
      />
      <path
        d="m4.365 6.846.99 1.485a.679.679 0 0 1-.148.912l-1.22.949a.676.676 0 0 0-.178.862c.358.643.696 1.401 2.376 3.081s2.439 2.018 3.081 2.376a.675.675 0 0 0 .863-.18l.947-1.218a.679.679 0 0 1 .913-.148l1.532 1.022 1.724 1.149c.303.2.394.602.208.913l-.817 1.362a1.661 1.661 0 0 1-.978.755c-1.357.373-4.351.448-9.15-4.351-4.8-4.8-4.727-7.793-4.354-9.15.117-.411.387-.761.755-.978l1.36-.817a.679.679 0 0 1 .914.207l1.182 1.77Z"
        fill="#4FBA6F"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .834)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default ContactIcon;
