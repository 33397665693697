/* eslint-disable react/prop-types */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { CommonFooter } from '../../shared-external/CommonFooter';

const footerQuery = graphql`
  query FooterQuery {
    gcms {
      jsonContaniers(where: { name: "New_Footer" }) {
        name
        content
      }
    }
  }
`;
const Footer = ({ data }) => {
  return (
    <CommonFooter
      data={data.gcms.jsonContaniers[0].content}
      shouldRenderDMCA={process.env.GATSBY_ACTIVE_ENV === 'production'}
    />
  );
};

const MainFooter = () => {
  return (
    <StaticQuery query={footerQuery} render={data => <Footer data={data} />} />
  );
};

export default MainFooter;
