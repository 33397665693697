import * as React from 'react';

const PhoneIcon = ({ color = '#00A66C', ...props }) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    {...props}
  >
    <g clipPath="url(#phone-icon)">
      <path
        d="M18.395 13.962c-1.224 0-2.427-.192-3.566-.568-.558-.19-1.245-.016-1.585.334l-2.249 1.697c-2.608-1.392-4.214-2.998-5.588-5.586l1.648-2.19a1.617 1.617 0 0 0 .398-1.638 11.373 11.373 0 0 1-.57-3.572c0-.885-.72-1.605-1.605-1.605H1.605C.72.834 0 1.554 0 2.439c0 10.143 8.252 18.395 18.395 18.395.885 0 1.605-.72 1.605-1.605v-3.662c0-.885-.72-1.605-1.605-1.605Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .834)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneIcon;
