import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import IconHamburger from '../shared/Header/IconHamburger';
import { colorsV2 } from '../style/colors-v2';
import { checkIfLinkOfCurrentPageIsActive } from '../utils/checkIfLinkOfCurrentPageIsActive';
import { fromScreen } from '../utils/media-query/responsive.util';
import { Link } from './Link';

const Main = styled.div`
  display: grid;
  grid-auto-flow: column;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  grid-template-columns: max-content max-content max-content minmax(0, 1fr);
  background-color: white;
  z-index: 200;
  .button-navigation {
    padding: 12px 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${colorsV2.black100};
    align-items: center;
    display: grid;
    place-items: center;
    border-top: 1px solid ${colorsV2.gray40};
    ${fromScreen(350)} {
      padding: 12px 16px;
    }
    &.active {
      background-color: ${colorsV2.primary10};
      color: ${colorsV2.primary100};
      border-top: 2px solid ${colorsV2.primary100};
    }
  }
  .button-navigation-hamburger {
    border-top: 1px solid ${colorsV2.gray40};
    display: grid;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 16px;
    > * {
      font-size: 20px;
      line-height: 0;
    }
  }
  ${fromScreen(1260)} {
    display: none;
  }
`;

const DEFAULT_MENU_LIST = [
  {
    id: 1,
    displayName: 'Giáo viên',
    to: '/linearteachers'
  },
  {
    id: 2,
    displayName: 'Điểm & Review',
    to: '/hall-of-fame'
  },
  {
    id: 3,
    displayName: 'Siêu ưu đãi',
    to: '/schedule'
  }
];

export const MobileBottomNav = ({ data = DEFAULT_MENU_LIST, onToggleMenu }) => {
  return (
    <Main className="mobile-bottom-navigation">
      {data.map(({ id, to, displayName }) => {
        return (
          <Link key={id} to={to}>
            <div
              className={cl('button-navigation', {
                active: checkIfLinkOfCurrentPageIsActive(to, to === '/')
              })}
            >
              {displayName}
            </div>
          </Link>
        );
      })}

      <div onClick={onToggleMenu} className="button-navigation-hamburger">
        <IconHamburger />
      </div>
    </Main>
  );
};
