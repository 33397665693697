import * as React from 'react';

function IconHamburger(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.667 5c0-.46.373-.833.833-.833h15a.833.833 0 010 1.666h-15A.833.833 0 011.667 5zm0 5.027c0-.46.373-.834.833-.834h15a.833.833 0 010 1.667h-15a.833.833 0 01-.833-.833zm0 5.027c0-.46.373-.834.833-.834h15a.833.833 0 110 1.667h-15a.833.833 0 01-.833-.833z"
        fill="#242938"
      />
    </svg>
  );
}

const MemoIconHamburger = React.memo(IconHamburger);
export default MemoIconHamburger;
