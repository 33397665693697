/* eslint-disable react/prop-types */
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import * as React from 'react';

import { ResponsiveTypography } from 'components/DesignSystemV2/ResponsiveTypography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { DCMA } from './DCMA';

const Main = styled.div`
  padding: 32px 0;
  border-top: 1px solid ${colorsV2.black80};

  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  align-items: start;
  gap: 12px;

  .list {
    display: flex;
    > * {
      &:not(:last-child) {
        padding-right: 8px;
        border-right: 1px solid ${colorsV2.gray100};
      }
      &:not(:first-child) {
        padding-left: 8px;
      }
    }
    a {
      color: inherit;
      &:hover {
        color: ${colorsV2.primary100};
      }
    }
  }

  ${fromScreen(776)} {
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    justify-content: space-between;
  }
`;

export const DmcaFooter = ({ list, shouldRenderDMCA }) => {
  return (
    <Main>
      <div>
        <ResponsiveTypography.Paragraph
          variant="regular/14-20"
          color="gray10"
          mb="8px"
        >
          © {new Date().getFullYear()} DOL English. All rights reserved.
        </ResponsiveTypography.Paragraph>
        <ResponsiveTypography.Paragraph
          variant="regular/14-20"
          color="gray10"
          mb="8px"
        >
          Thuộc sở hữu của Công ty TNHH DOL English <br />
          MST: 0315174306
        </ResponsiveTypography.Paragraph>
        <DCMA shouldRender={shouldRenderDMCA} />
      </div>
      <div className="list">
        {list?.map((item, idx) => (
          <a href={item.link} key={idx}>
            <ResponsiveTypography.Paragraph
              variant="regular/14-20"
              color="gray100"
            >
              {item?.name}
            </ResponsiveTypography.Paragraph>
          </a>
        ))}
      </div>
    </Main>
  );
};
