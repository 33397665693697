import * as React from 'react';
import PropTypes from 'prop-types';

import cl from 'classnames';
import styled, { css } from 'styled-components';

import { colorsV2 } from 'style/colors-v2';

import {
  deviceBreakpoints,
  deviceBreakpoints2,
  deviceBreakpoints3
} from '../../utils/hooks/useDeviceQuery.hook';

const DEFAULT_LINE_HEIGHT_MAP = {
  64: 72,
  56: 64,
  48: 56,
  44: 48,
  32: 40,
  24: 32,
  20: 28,
  18: 24,
  16: 24,
  15: 20,
  14: 20,
  13: 20,
  12: 16,
  10: 16
};

const VARIANT_FONT_WEIGHT_MAPPING = {
  regular: 400,
  medium: 500,
  'semi-bold': 600,
  bold: 700,
  black: 900
};

const DEFAULT_VARIANT = 'regular/14';

export const Typography = React.memo(props => {
  const {
    variant = DEFAULT_VARIANT,
    desktopVariant,
    tabletVariant,
    type = 'p',
    v2 = false,
    v3 = false,
    className,
    ...rest
  } = props;
  let Component = Text;

  const parsedVariant = parseVariant(variant);
  const parsedTabletVariant = tabletVariant
    ? parseVariant(tabletVariant)
    : parsedVariant;
  const parsedDesktopVariant = desktopVariant
    ? parseVariant(desktopVariant)
    : parsedTabletVariant;

  switch (type) {
    case 'p':
      Component = Text;
      break;
    case 'span':
      Component = Span;
      break;
    default:
      Component = Text;
  }

  return (
    <Component
      breakPointV2={v2}
      breakPointV3={v3}
      className={cl('typography', className)}
      variant={parsedVariant}
      tabletVariant={parsedTabletVariant}
      desktopVariant={parsedDesktopVariant}
      {...rest}
    />
  );
});

Typography.propTypes = {
  type: PropTypes.oneOf(['span', 'p']),
  variant: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  desktopVariant: PropTypes.string,
  tabletVariant: PropTypes.string,

  /** use deviceBreakpoints2 instead of deviceBreakpoints */
  v2: PropTypes.bool
};

export const parseVariant = variant => {
  const isIncludesLineHeight = /\d-\d/.test(variant);
  const [variantName, fontProperties] = variant.split('/');
  if (isIncludesLineHeight) {
    // Ex: regular/16-28
    const [fontSize, lineHeight] = fontProperties.split('-');
    return {
      fontSize: +fontSize,
      lineHeight,
      fontWeight: VARIANT_FONT_WEIGHT_MAPPING[variantName]
    };
  }

  const fontSize = +fontProperties;

  return {
    fontSize,
    lineHeight: DEFAULT_LINE_HEIGHT_MAP[fontSize],
    fontWeight: VARIANT_FONT_WEIGHT_MAPPING[variantName]
  };
};

const Text = styled.p`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  color: ${p => p.color || colorsV2.black100};

  font-size: ${p => p.variant.fontSize}px;
  line-height: ${p => p.variant.lineHeight}px;
  font-weight: ${p => p.variant.fontWeight};

  @media ${p =>
      p.breakPointV3 ? deviceBreakpoints3.md : deviceBreakpoints2.md} {
    ${p =>
      p.tabletVariant &&
      css`
        font-size: ${p.tabletVariant.fontSize}px;
        line-height: ${p.tabletVariant.lineHeight}px;
        font-weight: ${p.tabletVariant.fontWeight};
      `};
  }

  @media ${p =>
      p.breakPointV3 ? deviceBreakpoints3.lg : deviceBreakpoints2.lg} {
    ${p =>
      p.desktopVariant &&
      css`
        font-size: ${p.desktopVariant.fontSize}px;
        line-height: ${p.desktopVariant.lineHeight}px;
        font-weight: ${p.desktopVariant.fontWeight};
      `};
  }
`;

const Span = styled.span`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-family: 'Inter', sans-serif;
  color: ${p => p.color || colorsV2.black100};

  font-size: ${p => p.variant.fontSize}px;
  line-height: ${p => p.variant.lineHeight}px;
  font-weight: ${p => p.variant.fontWeight};

  @media ${p =>
      p.breakPointV2 ? deviceBreakpoints2.md : deviceBreakpoints.md} {
    ${p =>
      p.tabletVariant &&
      css`
        font-size: ${p.tabletVariant.fontSize}px;
        line-height: ${p.tabletVariant.lineHeight}px;
        font-weight: ${p.tabletVariant.fontWeight};
      `};
  }

  @media ${p =>
      p.breakPointV2 ? deviceBreakpoints2.lg : deviceBreakpoints.lg} {
    ${p =>
      p.desktopVariant &&
      css`
        font-size: ${p.desktopVariant.fontSize}px;
        line-height: ${p.desktopVariant.lineHeight}px;
        font-weight: ${p.desktopVariant.fontWeight};
      `};
  }
`;
