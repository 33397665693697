export const checkIfLinkOfCurrentPageIsActive = (link, exact = false) => {
  if (link && typeof window !== 'undefined' && window.location) {
    if (exact) {
      return (
        window.location.pathname.replace(/\//g, '') === link.replace(/\//g, '')
      );
    }
    return window.location.pathname
      .replace(/\//g, '')
      .includes(link.replace(/\//g, ''));
  }
  return false;
};

export const checkIfLinkOfCurrentPageIsActiveByPath = (
  path,
  link,
  exact = false
) => {
  if (link) {
    if (exact) {
      return path.replace(/\//g, '') === link.replace(/\//g, '');
    }
    return path.replace(/\//g, '').includes(link.replace(/\//g, ''));
  }
  return false;
};
