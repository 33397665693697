import * as React from 'react';

const GoogleMapIcon = props => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#google-map)">
      <path
        d="m10.645 11.322-2.147 3.319-2.049.877c-1.207-1.383-2.496-3.024-3.105-5.098L4.57 8.139l1.973-.918a3.526 3.526 0 0 0 4.102 4.102Z"
        fill="#FFDA2D"
      />
      <path
        d="M13.457 8.507c.036-.21.059-.42.059-.641a3.526 3.526 0 0 0-3.512-3.516H10c-.223 0-.434.024-.645.059l3.118-3.117a7.014 7.014 0 0 1 4.101 4.101L13.457 8.51v-.003Z"
        fill="#80AEF8"
      />
      <path
        d="m16.574 5.393-1.066 2.082-2.051 1.035v-.003a3.526 3.526 0 0 0-3.453-4.157h-.003v-.587l.702-1.561 1.77-.91a7.014 7.014 0 0 1 4.101 4.101Z"
        fill="#4086F4"
      />
      <path
        d="M16.574 5.393 13.457 8.51a3.552 3.552 0 0 1-2.812 2.813L7.25 14.716l-.802.802c.2.223.375.422.563.633 1.289 1.465 2.402 2.808 2.402 4.098a.58.58 0 0 0 .586.585.58.58 0 0 0 .586-.585c0-1.29 1.113-2.633 2.402-4.098 1.805-2.027 4.043-4.559 4.043-8.285 0-.867-.152-1.7-.457-2.473Z"
        fill="#59C36A"
      />
      <path
        d="M16.575 5.393 13.458 8.51a3.552 3.552 0 0 1-2.813 2.813l-.643.643v8.868a.58.58 0 0 0 .585-.585c0-1.29 1.113-2.633 2.402-4.098 1.805-2.027 4.043-4.559 4.043-8.285 0-.867-.152-1.7-.457-2.473Z"
        fill="#00A66C"
      />
      <path
        d="m10.001 11.966.643-.644a3.9 3.9 0 0 1-.643.059v.585Z"
        fill="#FDBF00"
      />
      <path
        d="M12.473 1.291 9.355 4.408c-.714.14-1.347.48-1.84.973l-1.812-.758-.672-1.727A6.966 6.966 0 0 1 10 .834c.867 0 1.7.152 2.473.457Z"
        fill="#4086F4"
      />
      <path
        d="m10.001 3.761 2.471-2.471a6.714 6.714 0 0 0-2.471-.457v2.928Z"
        fill="#4175DF"
      />
      <path
        d="M7.516 5.38a3.557 3.557 0 0 0-.973 1.84l-3.2 3.199a8.846 8.846 0 0 1-.374-2.555c0-1.945.785-3.703 2.062-4.968L7.516 5.38Z"
        fill="#F03800"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .834)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleMapIcon;
