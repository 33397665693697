// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { InView } from 'react-intersection-observer';

// import GlyphMediaIcButtonPlay from '@dolstaff/shared/es/icons/GlyphMediaIcButtonPlay';
// import Typo from '@dolstaff/shared/es/Typo';
// import cl from 'classnames';
// import { StaticQuery, graphql } from 'gatsby';
// import styled, { css } from 'styled-components';

// import getAssetLink from 'utils/getAssetLink';

// import { withFBSubscribeOnClick } from '../../utils/facebook/facebookPixel';
// import MyButton from '../MyButton';
import NewCTA from './new-cta';

// const BANNER_WIDTH = '764';

// const NavButton = styled(withFBSubscribeOnClick(MyButton))`
//   background-color: #de4c47;
//   color: white;
//   padding-left: 2em;
//   padding-right: 2em;
//   position: relative;
//   z-index: 9;
// `;

// const Main = styled.div`
//   position: fixed;
//   z-index: 99;
//   bottom: 0;
//   width: 100%;
//   height: 44px;
//   transition: all 0.6s;

//   &.inactive {
//     display: none;
//   }

//   ${({ active }) =>
//     active &&
//     css`
//       height: 464px;
//     `}

//   @media screen and (min-width: 1024px) {
//     bottom: unset;
//     top: 20%;
//     right: 0;
//     height: 430px;
//     width: 64px;

//     ${({ active }) =>
//       active &&
//       css`
//         width: ${BANNER_WIDTH}px;
//       `}
//   }
// `;

// const RedBanner = styled.div`
//   background-color: #d14242;
//   color: white;
//   text-transform: uppercase;
//   position: absolute;
//   width: 100%;
//   height: 48px;
//   padding: 6px 24px;
//   cursor: pointer;

//   .closeBtn {
//     padding: 1em;
//     position: absolute;
//     top: 0;
//     right: 0;
//     color: white;
//     background-color: transparent;
//     border: none;
//     display: block;
//   }

//   .title,
//   .subTitle {
//     color: white;
//     line-height: 1.2;
//   }
//   .subTitle {
//     font-size: 14px;
//   }
//   .title {
//     font-size: 16px;
//     font-weight: bold;
//   }

//   .iconArrow {
//     position: absolute;
//     top: 10px;
//     right: 24px;
//     transform: rotate(-90deg);
//     transition: all 0.6s;
//   }

//   &.active {
//     .iconArrow {
//       transform: rotate(-270deg);
//     }
//   }

//   @media screen and (min-width: 1024px) {
//     width: 300px;
//     transform-origin: 0 0;
//     transform: rotate(-90deg) translateX(-100%);
//     height: 64px;
//     padding: 0.7em 1em;
//     top: 0;
//     left: 0;

//     p {
//       line-height: 1.4;
//     }

//     .closeBtn {
//       display: none;
//     }

//     .iconArrow {
//       position: absolute;
//       right: 0.8em;
//       top: 50%;
//       margin-top: -4px;
//       transform: rotate(-90deg) translateX(50%);
//       transition: all 0.6s;
//     }

//     .title,
//     .subTitle {
//       line-height: 1.4;
//     }

//     &.active {
//       display: block;

//       .iconArrow {
//         transform: rotate(-270deg) translateX(-50%);
//       }
//     }
//   }
// `;

// const Content = styled.div`
//   position: relative;
//   z-index: 2;

//   .subTitle,
//   .title {
//     font-size: 18px;
//     color: #2d3742;
//     font-weight: bold;
//   }

//   .title {
//     font-size: 22px;
//     line-height: 1.2;
//     margin-bottom: 1em;
//     max-width: 500px;
//   }

//   .em,
//   strong {
//     color: #d14242;
//   }

//   @media screen and (min-width: 1024px) {
//     .title {
//       font-size: 32px;
//     }
//   }
// `;

// const ContentDesktopWrp = styled.div`
//   display: none;
//   position: relative;
//   background: #f4f5f7;
//   overflow: hidden;
//   box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
//   width: 700px;
//   height: 300px;
//   margin-top: 0;
//   margin-left: 64px;
//   padding: 2em;

//   @media screen and (min-width: 1024px) {
//     display: block;
//   }

//   .backgroundImg {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     max-height: 100%;
//     overflow: hidden;

//     img {
//       width: 100%;
//     }
//   }
// `;

// const ContentMobileWrp = styled.div`
//   position: relative;
//   background: #f4f5f7;
//   overflow: hidden;
//   box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
//   margin-top: 44px;
//   width: 100%;
//   height: 420px;
//   padding: 24px;

//   .closeBtn {
//     padding: 1em;
//     position: absolute;
//     top: 0;
//     right: 0;
//     background-color: transparent;
//     border: none;
//     display: block;
//   }

//   .backgroundImg {
//     position: absolute;
//     right: 0;
//     bottom: 0;
//     width: 375px;
//     height: 342px;
//     left: 50%;
//     transform: translateX(-50%);

//     img {
//       width: 100%;
//     }
//   }

//   @media screen and (min-width: 1024px) {
//     display: none;
//   }
// `;

// const bannerDefault = {
//   subtitle: 'đến 24/02/2020',
//   subtitle1: 'trước 24/02/2020',
//   title: 'Lì xì đến 1000.000 VNĐ',
//   title1: 'Lì xì lên đến 1.000.000đ',
//   ctaButtonLabel: 'Đăng ký nhận lì xì ngay',
//   ctaButtonLink: '/register',
//   content: {
//     html:
//       '<p>Mọi Khoá IELTS ở DOL đều là Chuẩn <strong>Đầu Ra</strong> - Chuẩn <strong>Tư Duy</strong></p>'
//   },
//   desktopImage: {
//     handle: '6yTYViaQe2QoEbx3u9Tg',
//     height: 936,
//     width: 1752
//   },
//   mobileImage: {
//     handle: 'qt2pDfp9T5GaqottwLTo',
//     height: 1368,
//     width: 1500
//   }
// };

// // const DolBigCTA = ({ ctaOptions }) => {
// //   const [active, setActive] = useState(-1);
// //   const defaultOptions = {
// //     onClick: () => setActive(0)
// //   };

// //   return (
// //     <StaticQuery
// //       query={graphql`
// //         query {
// //           atwSideBanners(first: 1) {
// //             id
// //             createdAt
// //             ctaButtonLabel
// //             desktopImage {
// //               createdAt
// //               fileName
// //               handle
// //               height
// //               id
// //               mimeType
// //               stage
// //               size
// //               updatedAt
// //               width
// //             }
// //             mobileImage {
// //               createdAt
// //               fileName
// //               handle
// //               height
// //               id
// //               mimeType
// //               size
// //               updatedAt
// //               stage
// //               width
// //             }
// //             stage
// //             subtitle
// //             subtitle1
// //             title
// //             title1
// //             updatedAt
// //           }
// //         }
// //       `}
// //     >
// //       {data => {
// //         const banner = {
// //           ...bannerDefault,
// //           ...data.atwSideBanners
// //         };
// //         const ctaButtonOptions = ctaOptions
// //           ? { ...defaultOptions, ...ctaOptions }
// //           : {
// //               ...ctaOptions,
// //               to: banner.ctaButtonLink || '/register'
// //             };
// //         return (
// //           <>
// //             <InView
// //               id="intersection-observer-dolBigCTA"
// //               style={{
// //                 position: 'absolute',
// //                 right: '0',
// //                 top: '60%',
// //                 width: '4px',
// //                 height: '4px'
// //               }}
// //               onChange={inView => {
// //                 if (inView && active === -1) {
// //                   setActive(1);
// //                 }
// //               }}
// //             />
// //             <Main active={active === 1} id="container-big-cta">
// //               <RedBanner
// //                 className={cl({ active: active === 1 })}
// //                 role="button"
// //                 onClick={() => {
// //                   if (active <= 0) {
// //                     setActive(1);
// //                   } else {
// //                     setActive(0);
// //                   }
// //                 }}
// //               >
// //                 <p className="subTitle">{banner.subtitle}</p>
// //                 <p className="title">{banner.title}</p>
// //                 <div className="iconArrow">
// //                   <GlyphMediaIcButtonPlay size={16} />
// //                 </div>
// //               </RedBanner>

// //               <ContentDesktopWrp
// //                 style={{
// //                   background: `whitesmoke url(${getAssetLink({
// //                     item: banner.desktopImage
// //                   })}) bottom right / contain no-repeat`
// //                 }}
// //               >
// //                 <Content>
// //                   <Typo className="title" htmlContent={banner.content.html} />
// //                   <div
// //                     style={{
// //                       color: '#2D3742',
// //                       marginBottom: '0.5em',
// //                       fontWeight: 'bold'
// //                     }}
// //                   >
// //                     {banner.title1}
// //                     <br />
// //                     {banner.subtitle1}
// //                   </div>
// //                   <NavButton {...ctaButtonOptions}>
// //                     {banner.ctaButtonLabel}
// //                   </NavButton>
// //                 </Content>
// //               </ContentDesktopWrp>

// //               <ContentMobileWrp
// //                 style={{
// //                   background: `whitesmoke url(${getAssetLink({
// //                     item: banner.mobileImage
// //                   })}) center center / contain no-repeat`
// //                 }}
// //               >
// //                 <Content>
// //                   <Typo className="title" htmlContent={banner.content.html} />
// //                   <div
// //                     style={{
// //                       color: '#2D3742',
// //                       marginTop: '14em',
// //                       marginBottom: '0.5em',
// //                       fontWeight: 'bold'
// //                     }}
// //                   >
// //                     {banner.title1}
// //                     <br />
// //                     {banner.subtitle1}
// //                   </div>
// //                   <NavButton {...ctaButtonOptions}>
// //                     {banner.ctaButtonLabel}
// //                   </NavButton>
// //                 </Content>
// //               </ContentMobileWrp>
// //             </Main>
// //           </>
// //         );
// //       }}
// //     </StaticQuery>
// //   );
// // };

// DolBigCTA.propTypes = {
//   ctaOptions: PropTypes.shape({})
// };

// export const LegacyDolBigCTA = DolBigCTA;
export default NewCTA;
