import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorsV2 } from '../../../style/colors-v2';

const VerticalLine = styled.div`
  width: ${p => p.size}px;
  height: 100%;
  margin: 0 ${p => p.space}px;
  background-color: ${p => p.color};
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: ${p => p.size}px;
  margin: ${p => p.space}px 0;
  background-color: ${p => p.color};
`;

export const DividerLineDivider = props => {
  const {
    direction = 'horizontal',
    size = 1,
    space = 0,
    color = colorsV2.gray20
  } = props;

  if (direction === 'vertical') {
    return <VerticalLine size={size} space={space} color={color} />;
  }

  return <HorizontalLine size={size} space={space} color={color} />;
};

DividerLineDivider.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  size: PropTypes.number,
  space: PropTypes.number,
  color: PropTypes.string
};
