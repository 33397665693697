/* eslint-disable */
import * as React from 'react';
import { useEffect } from 'react';

import Button from '@dolstaff/shared/es/Button';
import { graphql, StaticQuery, Link } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { CommonHeader } from '../../shared-external/CommonHeader';
import { UserPixelTracking } from 'utils/userPixelTracking';
import TagManager from 'react-gtm-module';

const DefaultCTAButton = (
  <div className="cta-actions">
    <Link
      to="https://m.me/dolenglish.ieltsdinhluc"
      target="_blank"
      rel="noopener noreferrer"
      className="action-link"
    >
      <Button
        size="large"
        className="btn-action inbox"
        type="primary"
        marginBottom="0"
      >
        Inbox DOL
      </Button>
    </Link>
  </div>
);

const DefaultCTATrackingEventButton = ({ onClickFunc }) => {
  return (
    <div className="cta-actions">
      <Link
        to="https://m.me/dolenglish.ieltsdinhluc"
        target="_blank"
        rel="noopener noreferrer"
        className="action-link"
      >
        <Button
          size="large"
          className="btn-action inbox"
          type="primary"
          marginBottom="0"
          onClick={onClickFunc}
        >
          Inbox DOL
        </Button>
      </Link>
    </div>
  );
};

export const HeaderSchedulePage = ({
  menuData,
  hideMobileBottomNav,
  hideMobileSideBar = false,
  noRedirectHome,
  CTAButton = DefaultCTAButton
}) => {
  return (
    <CommonHeader
      menuData={menuData}
      hideMobileBottomNav={hideMobileBottomNav}
      hideMobileSideBar={hideMobileSideBar}
      logoUrl={noRedirectHome ? '' : '/'}
      CTAButton={CTAButton}
    />
  );
};

const homeMenuQuery = graphql`
  {
    default: gcms {
      jsonContanier(where: { name: "Shared_TopBarMenu" }) {
        content
      }
    }

    dev: gcms {
      jsonContanier(where: { name: "Shared_TopBarMenu_Dev" }) {
        content
      }
    }
  }
`;

const HeaderSchedulePageMain = ({ ...rest }) => {
  useEffect(() => {
    !(function (w, d, t) {
      w.TiktokAnalyticsObject = t;
      var ttq = (w[t] = w[t] || []);
      (ttq.methods = [
        'page',
        'track',
        'identify',
        'instances',
        'debug',
        'on',
        'off',
        'once',
        'ready',
        'alias',
        'group',
        'enableCookie',
        'disableCookie',
        'holdConsent',
        'revokeConsent',
        'grantConsent'
      ]),
        (ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        });
      for (var i = 0; i < ttq.methods.length; i++)
        ttq.setAndDefer(ttq, ttq.methods[i]);
      (ttq.instance = function (t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      }),
        (ttq.load = function (e, n) {
          var r = 'https://analytics.tiktok.com/i18n/pixel/events.js',
            o = n && n.partner;
          (ttq._i = ttq._i || {}),
            (ttq._i[e] = []),
            (ttq._i[e]._u = r),
            (ttq._t = ttq._t || {}),
            (ttq._t[e] = +new Date()),
            (ttq._o = ttq._o || {}),
            (ttq._o[e] = n || {});
          n = document.createElement('script');
          (n.type = 'text/javascript'),
            (n.async = !0),
            (n.src = r + '?sdkid=' + e + '&lib=' + t);
          e = document.getElementsByTagName('script')[0];
          e.parentNode.insertBefore(n, e);
        });

      ttq.load(`C00H9EU9CSIJN7ECPGP0`);
      // ttq.page();
    })(window, document, 'ttq');

    const uid = uuidv4();
    UserPixelTracking.pageViewEventPixelSDK(uid);
    UserPixelTracking.pageViewEvent(uid);
    const tagManagerArgs = {
      gtmId: 'GTM-W2L6RPB'
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const onClick = e => {
    const uid = uuidv4();
    UserPixelTracking.clickEventPixelSDK(uid);
    UserPixelTracking.clickEvent(uid);
  };

  return (
    <StaticQuery
      query={homeMenuQuery}
      render={data => (
        <HeaderSchedulePage
          menuData={
            process.env.IS_PROD
              ? data.default.jsonContanier.content
              : data.dev.jsonContanier.content
          }
          CTAButton={<DefaultCTATrackingEventButton onClickFunc={onClick} />}
          {...rest}
        />
      )}
    />
  );
};

export default HeaderSchedulePageMain;
