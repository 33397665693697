import React from 'react';

import { withFBSubscribeOnClick } from '../../utils/facebook/facebookPixel';
import MyButton from './index';

export const FBSubscribeButton = withFBSubscribeOnClick(MyButton);

const RegisterButton = () => (
  <FBSubscribeButton
    id="btn-header-to-register"
    size="large"
    to="/register"
    style={{
      marginLeft: '1em',
      fontSize: '16px',
      fontWeight: '400'
    }}
  >
    Đăng ký tư vấn
  </FBSubscribeButton>
);

export default RegisterButton;
