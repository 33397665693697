import 'intersection-observer';

import * as React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import styled from 'styled-components';

import { getAssetLinkByHandle } from 'utils/getAssetLink';

/* eslint-disable react/prop-types */
export const Image = props => {
  const { src, alt, handle, imgProps, width, height } = props;

  return (
    <Img
      src={src || getAssetLinkByHandle({ handle, width, height })}
      loading="lazy"
      alt={alt}
      {...imgProps}
    />
  );
};

export const LazyImage = React.memo(props => {
  const { src, alt, handle, imgProps, width, height, ...rest } = props;
  const supportsLazyLoading = useNativeLazyLoading();
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px'
  });

  return (
    <div
      style={{ lineHeight: 0 }}
      ref={!supportsLazyLoading ? ref : undefined}
      {...rest}
    >
      {inView || supportsLazyLoading ? (
        <Image
          src={src}
          alt={alt}
          handle={handle}
          imgProps={imgProps}
          width={width}
          height={height}
        />
      ) : null}
    </div>
  );
});

const Img = styled.img`
  max-width: 100%;
`;

LazyImage.propTypes = {
  src: PropTypes.string,
  handle: PropTypes.string,
  imgProps: PropTypes.shape({})
};
