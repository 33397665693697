import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { CommonDolRegister } from 'shared-external/CommonDolRegister';
import DolInfo from 'shared/DolInfo';
import { navigate } from 'gatsby';
import { H2 } from '../MainLayout/Title';

const DefaultHeading = () => (
  <H2 style={{ maxWidth: '600px' }}>
    Đăng ký test đầu vào <br />
    <strong>miễn phí</strong> và nhận tư vấn
  </H2>
);

const Row = styled.div`
  margin-bottom: 32px;
  width: 100%;
  max-width: 600px;
`;

const slideInLeft = keyframes`
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const scaleOutCenter = keyframes`

  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
`;

export const AnimatedRow = styled(Row)`
  &.show {
    animation: ${slideInLeft} 700ms;
  }

  &.hide {
    animation: ${scaleOutCenter} 500ms;
  }
`;

const DolRegister = ({ Heading = DefaultHeading, ...rest }) => {
  return (
    <DolInfo>
      {({ dolInfo }) => (
        <CommonDolRegister
          onRegisterSuccess={() => {
            navigate('/thank-you');
          }}
          dolInfo={dolInfo}
          Heading={Heading}
          {...rest}
        />
      )}
    </DolInfo>
  );
};

DolRegister.propTypes = {
  theme: PropTypes.string.isRequired,
  done: PropTypes.bool,
  backgroundImg: PropTypes.shape({}),
  Heading: PropTypes.element,
  overrideDolInfo: PropTypes.shape({
    phone: PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string
    })
  })
};

DolRegister.defaultProps = {};

export default React.memo(DolRegister);
