import React from 'react';
import PropTypes from 'prop-types';

const MessengerIcon = ({ className, ...rest }) => (
  <svg
    className={className}
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 1C6.037 1 1 5.424 1 11.126c0 3.175 1.583 5.962 3.999 7.798v1.423L5 22a1 1 0 001.445.896l3.628-1.802a11.95 11.95 0 001.927.157c5.963 0 11-4.424 11-10.125C23 5.424 17.963 1 12 1zM3 11.126C3 6.702 6.959 3 12 3s9 3.702 9 8.126c0 4.423-3.959 8.125-9 8.125a9.95 9.95 0 01-1.887-.181 1 1 0 00-.634.086l-2.48 1.231v-1.973a1 1 0 00-.434-.826C4.38 16.09 3 13.748 3 11.127zm14.345-.798a1 1 0 10-1.2-1.6l-3.12 2.339-1.056-1.574a1 1 0 00-1.43-.242l-3.884 2.913a1 1 0 101.2 1.6l3.041-2.28 1.057 1.572a1 1 0 001.43.243l3.962-2.971z"
      clipRule="evenodd"
    />
  </svg>
);

MessengerIcon.propTypes = {
  className: PropTypes.string
};

MessengerIcon.defaultProps = {
  className: ''
};

export default MessengerIcon;
