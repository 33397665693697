import { GRAPH_CMS_CDN } from '../constant';

// an item is a Asset record in graph cms
export const getAssetLink = ({ item, width, height }) => {
  if (!item) return null;

  // without with or height the asset will be considered as videos or files
  if (!width && !height) {
    return `${GRAPH_CMS_CDN}/${item.handle}`;
  }

  if (width && !height) {
    return `${GRAPH_CMS_CDN}/resize=w:${width},fit:crop/compress/${item.handle}`;
  }

  if (!width && height) {
    return `${GRAPH_CMS_CDN}/resize=h:${height},fit:crop/compress/${item.handle}`;
  }

  return `${GRAPH_CMS_CDN}/resize=w:${width},h:${height},fit:crop/compress/${item.handle}`;
};

export const getAssetLinkByHandle = ({ handle, width, height }) => {
  return getAssetLink({ item: { handle }, width, height });
};

export default getAssetLink;

export const getGoogleDriveImageThumbnail = id =>
  `https://drive.google.com/thumbnail?authuser=0&id=${id}`;

export const getGoogleDriveImage = id => `https://drive.google.com/uc?id=${id}`;
