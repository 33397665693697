import React from 'react';

const isBrowser = () => typeof window !== 'undefined';

export const track = (event, data) => {
  if (isBrowser()) {
    const trackFn = window['fbq'];
    if (trackFn) {
      trackFn('track', event, data);
    }
  }
};

export const withFBEventOnClick = (WrappedComponent, event) => props => {
  const trackEvent = React.useCallback(() => {
    track(event);
  }, []);
  return <WrappedComponent onClick={trackEvent} {...props} />;
};

export const SUBSCRIBE_EVENT = 'Subscribe';
export const LEAD_EVENT = 'Lead';

export const withFBSubscribeOnClick = WrappedComponent => WrappedComponent;
