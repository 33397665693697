import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

const DolInfo = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "dol-info-v2" }) {
          content
        }
      }
    }
  `);

  return children({ dolInfo: data.gcms.jsonContanier.content });
};

DolInfo.propTypes = {
  children: PropTypes.node.isRequired
};

DolInfo.defaultProps = {};

export default React.memo(DolInfo);
