import { useMediaQuery } from 'react-responsive';

export default function () {
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  const isAboveTablet = useMediaQuery({
    query: '(min-width: 768px)'
  });

  return { isTabletOrMobile, isAboveTablet };
}
