import { useMediaQuery } from 'react-responsive';

export const deviceBreakpoints = {
  smallMobile: '(max-width: 375px)',
  mediumMobile: '(min-width: 376px) and (max-width: 427px)',
  largeMobile: '(min-width: 427px) and (max-width: 767px)',

  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1365px)',
  desktop: '(min-width: 1366px)',

  xs: '(min-width: 375px)',
  sm: '(min-width: 480px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1366px)'
};

export const useDeviceDetect = () => {
  const isDesktop = useMediaQuery({
    query: deviceBreakpoints.desktop
  });
  const isMobile = useMediaQuery({
    query: deviceBreakpoints.mobile
  });
  const isTablet = useMediaQuery({
    query: deviceBreakpoints.tablet
  });

  return {
    isMobile,
    isTablet,
    isDesktop
  };
};

/** New break point system
 * 360 -540
 * 541 - 767
 * 768-1134
 * 1135+
 * Page applied: Home page
 * Ref: https://www.figma.com/file/t7DOrROtGvykv92CgvzETR/dol.vn-Full-flow-listing?node-id=1648%3A340224
 */
export const deviceBreakpoints2 = {
  smallMobile: '(max-width: 540px)',
  largeMobile: '(min-width: 541px) and (max-width: 767px)',
  mobile: '(max-width: 767px)',

  tablet: '(min-width: 768px) and (max-width: 1134px)',

  desktop: '(min-width: 1135px)',

  xs: '(min-width: 360px)',
  sm: '(min-width: 541px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1135px)'
};

export const deviceBreakpoints3 = {
  sm: '(min-width: 375px)',

  md: '(min-width: 458px)',
  lg: '(min-width: 1144px)',

  mobile: '(max-width: 775px)',

  tablet: '(min-width: 776px) and (max-width: 1143px)',

  desktop: '(min-width: 1144px)'
};

export const useDeviceDetect3 = () => {
  const isDesktop = useMediaQuery({
    query: deviceBreakpoints3.desktop
  });
  const isMobile = useMediaQuery({
    query: deviceBreakpoints3.mobile
  });
  const isTablet = useMediaQuery({
    query: deviceBreakpoints3.tablet
  });

  return {
    isMobile,
    isTablet,
    isDesktop
  };
};

export const useDeviceDetect2 = () => {
  const isDesktop = useMediaQuery({
    query: deviceBreakpoints.desktop
  });
  const isMobile = useMediaQuery({
    query: deviceBreakpoints.mobile
  });
  const isTablet = useMediaQuery({
    query: deviceBreakpoints.tablet
  });
  const isSmallMobile = useMediaQuery({
    query: deviceBreakpoints.smallMobile
  });
  const isLargeMobile = useMediaQuery({
    query: deviceBreakpoints.smallMobile
  });

  return {
    isMobile,
    isTablet,
    isDesktop,
    isSmallMobile,
    isLargeMobile
  };
};
