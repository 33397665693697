import * as React from 'react';

export const DCMA = ({ shouldRender = false }) => {
  if (shouldRender)
    return (
      <div>
        <a
          href="//www.dmca.com/Protection/Status.aspx?ID=36c8b284-aa13-4f7c-96a6-e7e419ec8411"
          title="DMCA.com Protection Status"
          className="dmca-badge"
        >
          <img
            width={102}
            height={24}
            src="https://images.dmca.com/Badges/dmca_protected_sml_120i.png?ID=36c8b284-aa13-4f7c-96a6-e7e419ec8411"
            alt="DMCA.com Protection Status"
          />
        </a>
        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
      </div>
    );

  return null;
};
