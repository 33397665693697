import * as React from 'react';

function Close(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.226 4.811A1 1 0 004.81 6.225L10.586 12l-5.774 5.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.415 12l5.774-5.775a1 1 0 10-1.414-1.414L12 10.586 6.226 4.81z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoClose = React.memo(Close);
export default MemoClose;
