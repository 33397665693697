/* eslint-disable react/prop-types */
import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { dolCTAReferences } from '../constant';
import { DolContact } from '../shared/DolRegister/DolContact';
import { AnimatedImageLayout } from './AnimatedImageLayout';

const Row = styled.div`
  margin-bottom: 32px;
  width: 100%;
  max-width: 600px;
`;

const scaleOutCenter = keyframes`

  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
`;

export const AnimatedRow = styled(Row)`
  animation: ${scaleOutCenter} 500ms;
`;

export const CommonDolRegister = props => {
  const { theme, overrideDolInfo, Heading, dolInfo, customEnvironment } = props;

  return (
    <AnimatedImageLayout className="common-dol-register" theme={theme}>
      <AnimatedRow id={dolCTAReferences.REGISTER}>
        <DolContact
          customEnvironment={customEnvironment}
          Heading={Heading}
          overrideDolInfo={overrideDolInfo}
          onInfoClick={() => {}}
          dolInfo={dolInfo}
        />
      </AnimatedRow>
    </AnimatedImageLayout>
  );
};
