import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import DolRegister from 'shared/DolRegister';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled, { ThemeProvider } from 'styled-components';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';
import { shaking } from 'shared/DolBigCTA/animations/shaking';
import { ContactDol } from 'shared/InboxDolBtn/InboxDolBtn';
import { CTAForScheduleAndRegister } from 'shared/DolBigCTA/cta-schedule-register';

import GlobalStyle from '../../style/GlobalStyle';
import theme from '../../style/theme';
import DolBigCTA from '../DolBigCTA';
import Footer from '../Footer';
import { HEADER_HEIGHT } from '../Header';

const Main = styled.main`
  position: relative;
  font-family: 'Inter';
  .inbox-dol-btn {
    position: fixed;
    z-index: 100;
    bottom: 70px;
    right: 50px;
  }
  .flex {
    flex: 1;
  }
  .section + .section {
    padding-top: 0;
  }
  .section:last-child {
    > .container {
      border-bottom: none;
    }
  }
  .row {
    display: flex;
  }
  .row-section {
    margin: 0 auto;
    width: 100%;
    max-width: 428px;
    padding: 0 16px;
  }
  .content-row {
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
  .with-right-cta {
    width: auto;
  }
  .txt-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
  .layout-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;

    margin: -32px 0 0 -32px;
    width: calc(100% + 32px);
    > * {
      margin: 32px 0 0 32px;
    }
  }

  .row-separator {
    display: flex;
    justify-content: center;
    align-items: center;

    &.as-line {
      margin-top: 32px;
      margin-bottom: 32px;
      border-top: 1px solid ${colorsV2.gray40};
    }
  }

  @media ${deviceBreakpoints.md} {
    .row-section {
      max-width: 768px;
    }
  }

  @media ${deviceBreakpoints.lg} {
    padding-bottom: 0;
    .row-section {
      max-width: 1120px;
      padding: 0;
    }
    .with-right-cta {
      width: calc(1120px - 349px);
    }
  }

  ${shaking}
`;

const MainLayout = ({
  children,
  noHeader,
  noFooter,
  noMenu,
  hideSideCTA,
  showFooterRegister = true,
  paddingTop = `${HEADER_HEIGHT}px`,
  hideMobileBottomNav,
  renderCustomHeader,
  ...props
}) => {
  // Track UTM
  if (typeof window !== 'undefined') {
    const utmQuery = decodeURIComponent(window.location.search.substring(1));
    const utmVariables = utmQuery.split('&');
    let ParameterName = '';

    const getUTMValue = inputParameter => {
      for (let i = 0; i < utmVariables.length; i++) {
        ParameterName = utmVariables[i].split('=');
        if (ParameterName[0] === inputParameter) {
          return ParameterName[1] === null ? null : ParameterName[1];
        }
      }
      return undefined;
    };

    const valueExists = value => {
      return value != null && value !== '' && value !== undefined;
    };

    const utmParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term'
    ];

    utmParams.forEach(param => {
      const pValue = getUTMValue(param);

      if (valueExists(pValue)) {
        Cookies.set(param, pValue, {
          domain: window.location.domain,
          expires: 90
        });
      }
      const cValue = Cookies.get(param);
      const input = document.getElementById(param);
      if (input && valueExists(cValue)) {
        input.value = cValue;
      }
    });
    // End Track UTM
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ paddingTop }}>
        <CTAForScheduleAndRegister />
        <GlobalStyle />
        {!noHeader && (
          <HeaderSchedulePage
            noMenu={noMenu}
            hideMobileBottomNav={hideMobileBottomNav}
          />
        )}
        {renderCustomHeader && renderCustomHeader()}
        <Main {...props}>
          {!hideSideCTA && <DolBigCTA />}
          <ContactDol />
          {children}
        </Main>
        {showFooterRegister && <DolRegister />}
        {!noFooter && <Footer />}
      </div>
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  paddingTop: PropTypes.string,
  noHeader: PropTypes.bool,
  noMenu: PropTypes.bool,
  noFooter: PropTypes.bool,
  hideSideCTA: PropTypes.bool,
  renderCustomHeader: PropTypes.func
};

export default MainLayout;
