import cl from 'classnames';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { getAssetLinkByHandle } from 'utils/getAssetLink';

import { fromScreen } from '../utils/media-query/responsive.util';

const kenburnsBottomRight = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const Main = styled.div`
  background-color: #fbfbfb;
  position: relative;
  padding: 32px 16px;
  .backgroundWrp {
    display: none;
    min-height: 100%;
    max-height: 320px;
    overflow: hidden;
    max-width: 100vw;
    margin-top: -2em;
    margin-bottom: 2em;
    .imgWrp {
      width: 100%;
      animation: ${kenburnsBottomRight} 24s linear both infinite;
    }
  }

  .gradientWrp {
    display: none;
  }

  .container {
    position: relative;
    z-index: 100;
  }

  ${fromScreen(458)} {
    .container {
      width: 425px;
      margin: 0 auto;
    }
  }

  ${fromScreen(776)} {
    padding-top: 6em;
    padding-bottom: 6em;

    .backgroundWrp {
      display: block;
      z-index: 40;
      position: absolute;
      left: 40%;
      right: 0;
      margin-top: -6em;
      margin-bottom: -6em;

      .imgWrp {
        &:nth-child(1) {
          display: block;
        }
      }
    }
    .container {
      width: 712px;
    }
    .gradientWrp {
      z-index: 41;
      display: block;
      position: absolute;
      top: -1px;
      right: 0;
      bottom: -1px;
      left: 0;
      background: linear-gradient(
        103.27deg,
        #f9fafa 42.51%,
        rgba(249, 250, 250, 0) 100.83%
      );
      &.white {
        background: linear-gradient(
          103.27deg,
          #f9fafa 42.51%,
          rgba(249, 250, 250, 0) 100.83%
        );
      }
    }
  }

  ${fromScreen(1144)} {
    .backgroundWrp {
      .imgWrp {
        min-width: 120%;
      }
    }
    .container {
      width: 1080px;
    }
  }
`;

const bgImageHandle = 'r5OGZsFBQfSD8rRAhQCH';

export const AnimatedImageLayout = ({ theme, children, className }) => {
  return (
    <Main className={`common-dol-register ${className}`}>
      <div className="backgroundWrp">
        {[1, 2].map(item => {
          return (
            <div key={item} className="imgWrp">
              <img
                src={getAssetLinkByHandle({
                  handle: bgImageHandle,
                  width: 1080
                })}
                alt="about-dol"
              />
            </div>
          );
        })}
      </div>
      <div className={cl('gradientWrp', theme)} />
      <div className="container">{children}</div>
    </Main>
  );
};
