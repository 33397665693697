import cl from 'classnames';
import * as React from 'react';
import styled, { css } from 'styled-components';

import {
  composeBreakPointStyle,
  numberToPxCss,
  responsiveBreakPointPropsToTrasientProps
} from './ResponsiveSystem';

const VARIANT_FONT_WEIGHT_MAPPING = {
  regular: 400,
  medium: 500,
  'semi-bold': 600,
  semibold: 600,
  bold: 700,
  black: 900
};

const customResolveAttr = (attrName, attrValue) => {
  if (attrName === '$variant') {
    let lineHeight = '1.5em';

    const isValidVariant = Object.keys(VARIANT_FONT_WEIGHT_MAPPING).some(
      fontWeightName => {
        if (attrValue) {
          return attrValue.toLowerCase().includes(fontWeightName);
        }
        return false;
      }
    );
    if (!isValidVariant)
      throw new Error(
        `Variant name "${attrName}" with value "${attrValue}" not valid, for example: Bold/16px | 24px`
      );
    const [variantName, fontProperties] = attrValue.split('/');
    const [fontSize, lineHeightExtracted] = fontProperties.split(/\||-/g);
    if (lineHeightExtracted) {
      lineHeight = lineHeightExtracted;
    }
    return `font-weight: ${
      VARIANT_FONT_WEIGHT_MAPPING[variantName.trim().toLowerCase()]
    };
      line-height: ${numberToPxCss(lineHeight)};
      font-size: ${numberToPxCss(fontSize)};
    `;
  }
  return `${attrName}: ${attrValue};`;
};

const shareStyle = css`
  color: inherit;
  &.responsive-typography.dol.dol-typo {
    margin: 0;
    ${p => composeBreakPointStyle(p, ['ellipsis'], customResolveAttr)}
  }
`;

const EnhancedAntdText = styled.span.withConfig({
  componentId: 'ResponsiveTypography_EnhancedAntdText'
})`
  ${shareStyle};
`;

const Text = props => {
  const { className, ...innerProps } = props;
  const { children, style, ellipsis, ...rest } = innerProps;

  return (
    <EnhancedAntdText
      className={cl('responsive-typography', 'dol', 'dol-typo', className)}
      style={style}
      ellipsis={ellipsis}
      {...responsiveBreakPointPropsToTrasientProps(rest, ['variant'])}
    >
      {children}
    </EnhancedAntdText>
  );
};

const EnhancedAntdParagraph = styled.p.withConfig({
  componentId: 'ResponsiveTypography_EnhancedAntdParagraph'
})`
  ${shareStyle};
`;

const Paragraph = props => {
  const { className, ...innerProps } = props;
  const { style, ellipsis, ...rest } = innerProps;

  return (
    <EnhancedAntdParagraph
      {...rest}
      className={cl('responsive-typography', 'dol', 'dol-typo', className)}
      style={style}
      ellipsis={ellipsis}
      {...responsiveBreakPointPropsToTrasientProps(rest, ['variant'])}
    />
  );
};

export const ResponsiveTypography = {
  Text: React.memo(Text),
  Paragraph: React.memo(Paragraph)
};
