import React from 'react';

const Phone = props => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2088 13.1805L12.5354 15.2726C9.94343 13.7494 7.78342 11.5896 6.26011 8.99776L8.35171 7.32429C8.59691 7.12807 8.77019 6.85615 8.84449 6.55102C8.91879 6.24589 8.88991 5.92474 8.76238 5.63776L6.85558 1.34443C6.71877 1.03644 6.477 0.787083 6.17339 0.640834C5.86978 0.494584 5.52408 0.46096 5.19798 0.545959L1.56871 1.48629C1.22988 1.57465 0.935268 1.78431 0.740774 2.07549C0.54628 2.36668 0.465435 2.71912 0.513578 3.06596C1.15091 7.6019 3.24744 11.8071 6.4863 15.0461C9.72515 18.285 13.9303 20.3816 18.4662 21.0191C18.813 21.067 19.1653 20.9861 19.4563 20.7916C19.7474 20.5971 19.957 20.3026 20.0454 19.964L20.9862 16.3338C21.0708 16.0077 21.0369 15.6622 20.8906 15.3587C20.7443 15.0553 20.4951 14.8136 20.1873 14.6766L15.894 12.7722C15.6074 12.6447 15.2867 12.6157 14.9819 12.6895C14.6771 12.7634 14.4053 12.936 14.2088 13.1805Z"
        fill="#CD483D"
      />
    </svg>
  );
};

Phone.propTypes = {};

Phone.defaultProps = {};

export default React.memo(Phone);
