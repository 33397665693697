import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { Link } from './Link';

const Main = styled.div`
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  color: #242938;
  .menu-item {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const NavMenuItem = ({ className, text, link, external }) => {
  if (external) {
    return (
      <Main className={cl('nav-menu-item', { 'empty-link': !link })}>
        {link ? (
          <a
            key={text + link}
            href={`${link}`}
            target="_blank"
            rel="noopener noreferrer noffolow"
            className={cl('menu-item', className)}
          >
            {text}
          </a>
        ) : (
          <div className={cl('menu-item', className)}>{text}</div>
        )}
      </Main>
    );
  }

  return (
    <Main className={cl('nav-menu-item', { 'empty-link': !link })}>
      {link ? (
        <Link
          className={cl('menu-item', className)}
          key={text + link}
          to={link}
        >
          {text}
        </Link>
      ) : (
        <div className={cl('menu-item', className)}>{text}</div>
      )}
    </Main>
  );
};
