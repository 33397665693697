import * as React from 'react';

const MessengerIcon = ({ color = '#2196F3', ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#messenger-icon)">
      <path
        d="M0 10.093c0 2.914 1.453 5.513 3.726 7.21v3.531l3.405-1.868c.908.25 1.87.387 2.868.387 5.523 0 10-4.145 10-9.259C20 4.98 15.523.834 10 .834c-5.522 0-10 4.145-10 9.26ZM8.945 7.5l2.608 2.716L16.46 7.5l-5.466 5.802-2.546-2.716-4.97 2.716L8.945 7.5Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .834)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default MessengerIcon;
