import Portal from '@reach/portal';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { fromScreen } from '../utils/media-query/responsive.util';

const SIDE_BAR_WIDTH = 284;
const HEADER_HEIGHT = 64;

const MobileSidebarWrapper = styled.div`
  .sidebar-drawer {
    position: fixed;
    left: 0;
    top: ${HEADER_HEIGHT}px;
    bottom: 0;
    z-index: 101;
    background-color: white;
    max-height: calc(100vh - ${HEADER_HEIGHT}px);
    width: ${SIDE_BAR_WIDTH}px;
    transition: all 0.2s ease;
    transform: translateX(-${SIDE_BAR_WIDTH}px);
    box-shadow: rgb(0 0 0 / 6%) 0px 8px 24px 0px;

    &.open {
      transform: translateX(0px);
    }
  }
  .sidebar-drawer-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
  .header-bar {
    padding: 14px 32px;
    font-size: 38px;
    line-height: 0;
    width: ${SIDE_BAR_WIDTH}px;
  }

  ${fromScreen(1260)} {
    display: none;
  }
`;

export const MobileSidebar = ({ children, open, onVisibleChange }) => {
  return (
    <Portal>
      <MobileSidebarWrapper>
        <div
          className={cl('sidebar-drawer', {
            open
          })}
        >
          {children}
        </div>
        {open && (
          <div
            onClick={() => onVisibleChange(false)}
            className="sidebar-drawer-overlay"
          />
        )}
      </MobileSidebarWrapper>
    </Portal>
  );
};
